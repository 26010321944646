const VideoVertical = (idPlayer: string, placeholderSrc?: string) => {

	return (
		<div
			id="player-wrapper"
			className="hmedia video-stream u-video"
			style={{
				width: "100%",
				aspectRatio: "9/16",
				position:'relative',
				marginBottom:'10px'
			}}

		>

			<div className="lds-dual-ring" />
			<img
				src={placeholderSrc ?? "/assets/placeholder_.svg"}
				alt="Video imagen default "
				width="384"
				height="216"
				style={{
					position: "absolute",
					top: "0",
					left: "0",
					width: "100%",
					height: "100%",
					objectFit:'contain'
				}}
			/>

			<div id={idPlayer} />
		</div>
	);
};
export default VideoVertical;
