type WarningType = "GeoblockWarning" | "NotAvailableWarning";

export function createErrorMesageHTML(type: WarningType) {
	interface WarningMessages {
		GeoblockWarning: { es: string; en: string };
		NotAvailableWarning: { es: string; en: string };
	}
	const messages: WarningMessages = {
		GeoblockWarning: {
			es: "Este video no está disponible en tu país",
			en: "This video is not available in your country",
		},
		NotAvailableWarning: {
			es: "Este video no se puede reproducir",
			en: "This video cannot be played",
		},
	};

	const wrapper = document.createElement("div");
	wrapper.style.width = "100%";
	wrapper.style.aspectRatio = "16/9";
	wrapper.style.backgroundColor = "black";
	wrapper.style.display = "flex";
	wrapper.style.gap = "16px";
	wrapper.style.flexDirection = "column";
	wrapper.style.justifyContent = "center";
	wrapper.style.alignItems = "center";
	wrapper.style.position = "absolute";
	wrapper.style.top = "0";
	wrapper.style.left = "0";
	const placeholderES = document.createElement("h2");
	placeholderES.style.color = "white";
	placeholderES.style.padding = "16px 0";
	placeholderES.style.borderBottom = "1px solid white";
	const placeholderEN = document.createElement("h2");
	placeholderEN.style.color = "white";
	placeholderEN.style.padding = "8px 0";

	wrapper.appendChild(placeholderES);
	wrapper.appendChild(placeholderEN);

	placeholderES.textContent = messages[type]["es"];
	placeholderEN.textContent = messages[type]["en"];

	return wrapper;
}
