import { getRandomUUid } from "../../../app/helpers/";

import style from "@/app/styles/mapbox/StoryTellingLatin.module.scss";

import type { StoryTellingConfig } from "@/app/types";

export const storyTellingConfig: StoryTellingConfig = {
	style: "mapbox://styles/cipolnmas/clzhmh52300io01qqaeuj1arz",
	accessToken: "",
	showMarkers: false,
	markerColor: "#3FB1CE",
	inset: true,
	theme: "light",
	use3dTerrain: false,
	auto: false,
	title:
		"¿Los Latinos Podrían Definir al Próximo Presidente de EUA?",
	subtitle:
		"De minoría a motor de cambio: el creciente poder numérico y la diversidad de los hispanos están transformando la política de Estados Unidos.",
	byline: "",
	footer: "",
	chapters: [
		{
			id: `${getRandomUUid()}`,
			alignment: "left",
			hidden: false,
			title: "",
			image: "",
			layerName: "% de votantes hispanos por estado",
			description: `En 1960, la campaña del candidato demócrata John F. Kennedy transmitió el primer anuncio en español en la historia de Estados Unidos, dirigido a los votantes de origen puertoriqueño y mexicano. Aunque la protagonista era su esposa, Jackie Kennedy. Para ese año, California y Texas eran ya los estados con más hispanos, según el censo.`,
			location: {
				center: [-100.50106, 34.41058],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 1,
				},
				{
					layer: "votantes_hispanos_2_desechado",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: `${getRandomUUid()}-chapter-jackie-video`,
			alignment: "left",
			hidden: false,
			title: "",
			layerName: "% de votantes hispanos por estado",
			image: "",
			isVideo: true,
			description: `
			<div id="jackie-video" class="${style["latinMap__iframe"]}"></div>
			<p class="${style["latinMap__iframe__cite"]}"> <span class="${style["latinMap__iframe__cite--bold"]}">Fuente:</span> <cite class="${style["latinMap__iframe__cite--italic"]}">Museum of the Moving Image. The Living Room Candidate Presidential Campaign Commercials 1952-2024</cite>.</p>

			`,
			location: {
				center: [-100.50106, 34.41058],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 1,
				},
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "censo-hispanics-county",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "right",
			hidden: false,
			title: "",
			image: "",
			layerName: "% de hispanos por condado",

			description: `En aquella época, la población hispana era de 4.5 millones de personas, en un país de casi 180 millones de habitantes. Hoy viven más de 65 millones de hispanos en Estados Unidos. Desde 1980, su porcentaje del electorado casi se ha multiplicado por cuatro. Son la minoría étnica con más peso en el país.`,
			location: {
				center: [-100.50106, 34.41058],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
				{
					layer: "censo-hispanics-county",
					opacity: 1,
				},
			],
			onChapterExit: [],
		},
		{
			id: `${getRandomUUid()}-electorado-eua`,
			alignment: "left",
			hidden: false,
			title: "",
			image: "",
			isChart: true,
			layerName: "% de hispanos por condado ",

			description: `<div id="electorado-eua-chart"></div>
			<p class="${style["latinMap__iframe__cite__chart"]}"> 
								<span class="${style["latinMap__iframe__cite__chart--bold"]}">Fuente:</span>
								<cite class="${style["latinMap__iframe__cite__chart--italic"]}">Center for American Progress</cite>.
			</p>`,
			location: {
				center: [-100.50106, 34.41058],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
				{
					layer: "censo-hispanics-county",
					opacity: 1,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "left",
			hidden: false,
			title: "",
			image: "",
			layerName: "% de hispanos por condado",

			description: `Esto se debe a la inmigración y las tasas de fertilidad: los hispanos tienen, en promedio, más hijos que los blancos, afroamericanos y asiáticos. Entre 2022 y 2023, 7 de cada 10 nacimientos en el país fueron de familias hispanas.`,
			location: {
				center: [-100.50106, 34.41058],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
				{
					layer: "censo-hispanics-county",
					opacity: 1,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "right",
			hidden: false,
			title: "",
			image: "",
			layerName: "% de votantes hispanos por estado",

			description: `La mayoría de los hispanos viven en estados del sur, desde California hasta Florida.`,
			location: {
				center: [-105.77195, 32.47642],
				zoom: 4.26,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "votantes_hispanos",
					opacity: 1,
				},
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "censo-hispanics-county",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "left",
			hidden: false,
			title: "",
			layerName: "% de votantes hispanos por estado",
			image: "",
			description: `En Arizona, Nevada y Florida, los hispanos sobrepasan el 20% de los votantes; en Texas y California el 30%, mientras que en Nuevo México alcanzan el 45%.`,
			location: {
				center: [-105.77195, 32.47642],
				zoom: 4.26,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "votantes_hispanos",
					opacity: 1,
				},
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: `${getRandomUUid()}-hispanos-estado`,
			alignment: "right",
			hidden: false,
			title: "",
			layerName: "% de votantes hispanos por estado",
			image: "",
			isChart: true,
			description: `<div id="hispanos-estado-chart"></div>
			<p class="${style["latinMap__iframe__cite__chart"]}"> 
								<span class="${style["latinMap__iframe__cite__chart--bold"]}">Fuente:</span>
								<cite class="${style["latinMap__iframe__cite__chart--italic"]}">Pew Research Center</cite>.
			</p>`,
			location: {
				center: [-105.77195, 32.47642],
				zoom: 4.26,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "votantes_hispanos",
					opacity: 1,
				},
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},

		{
			id: getRandomUUid(),
			alignment: "left",
			hidden: false,
			title: "",
			image: "",
			layerName: "% de votantes hispanos por estado",
			description: `Estos estados alguna vez pertenecieron al México independiente o al viejo Virreinato de la Nueva España. Por esa razón y por las grandes olas de migrantes del siglo XX, la mayor parte de los hispanos son de origen mexicano.`,
			location: {
				center: [-106.7396, 30.62635],
				zoom: 2.81,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "votantes_hispanos",
					opacity: 1,
				},
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "right",
			hidden: false,
			title: "",
			layerName: "% de votantes hispanos por estado",
			isImage: true,
			image:
				"https://storage.googleapis.com/nmas-mapbox-images/story_telling/voto_latino/mexico_antiguo_latin.jpg",
			description: `<p class="${style["latinMap__iframe__cite"]}"> 
								<span class="${style["latinMap__iframe__cite--bold"]}">Fuente:</span>
								<cite class="${style["latinMap__iframe__cite--italic"]}">Tanner, H. Rumsey Collection. Map Of The United States Of Mexico, 1846</cite>.
						</p>`,
			location: {
				center: [-106.7396, 30.62635],
				zoom: 2.81,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "votantes_hispanos",
					opacity: 1,
				},
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "left",
			hidden: false,
			title: "",
			layerName: "Otros estados con gran % de hispanos",
			image: "",
			description: `Pero la población hispana no se ha quedado en la región del sur. En las últimas décadas, este grupo se ha dispersado y crecido en estados al interior del país y en la costa este, lugares como Illinois, Nueva Jersey, Connecticut, Rhode Island y Nueva York.`,
			location: {
				center: [-82.62465, 33.3267],
				zoom: 3.35,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 1,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "left",
			hidden: false,
			title: "",
			layerName: "Otros estados con gran % de hispanos",
			image: "",
			description: `La cultura hispana se ha vuelto parte del crisol que es Estados Unidos. Entre latinos o hispanos, hay opiniones que abarcan todo el espectro político.`,
			location: {
				center: [-82.62465, 33.3267],
				zoom: 3.35,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 1,
				},
			],
			onChapterExit: [],
		},
		{
			id: `${getRandomUUid()}-chapter-trum-kamala-video`,
			alignment: "left",
			hidden: false,
			layerName: "Otros estados con gran % de hispanos",
			title: "",
			image: "",
			isVideo: true,
			description: `<div id="trum-kamala-video"  class="${style["latinMap__iframe"]} ${style["latinMap__iframe--noCite"]}"></div>
`,
			location: {
				center: [-82.62465, 33.3267],
				zoom: 3.35,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 1,
				},
			],
			onChapterExit: [],
		},
		{
			id: `${getRandomUUid()}`,
			alignment: "right",
			hidden: false,
			title: "",
			image: "",
			layerName: "Resultados de elecciones 2020",
			description: `

			En 2024 hay elecciones presidenciales y se ha especulado mucho sobre cuál será el papel o impacto de los hispanos. ¿Están cambiando la política estadounidense? ¿O acaso el panorama político ha cambiado sus prioridades? El mapa de fondo muestra como quedó el país hace 4 años cuando ganó Biden.`,

			location: {
				center: [-100.50106, 35.57859],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "condados_dems",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: `${getRandomUUid()}`,
			alignment: "right",
			hidden: false,
			title: "",
			image: "",
			layerName: "Resultados de elecciones 2020",
			description: `
			En cada elección, se vuelve a hablar del “voto hispano” o “latino”. Se suele mencionar la importancia creciente de esta comunidad en las elecciones de Estados Unidos. Sin embargo, al agrupar a las comunidades hispanas del país, este concepto simplifica sus matices y su diversidad.`,

			location: {
				center: [-100.50106, 35.57859],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "condados_dems",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: `${getRandomUUid()}`,
			alignment: "left",
			hidden: false,
			layerName: "Condados demócratas emblemáticos",
			title: "",
			image: "",
			description: `
			<p>
			Históricamente, la mayoría de los hispanos han votado por el Partido Demócrata. Especialmente en grandes ciudades como San Antonio, Houston, Dallas, o en los condados fronterizos, donde el porcentaje de hispanos es alto.
			</p>`,
			location: {
				center: [-101.366, 25.32106],
				zoom: 4.08,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "condados_dems",
					opacity: 1,
				},
			],
			onChapterExit: [],
		},
		{
			id: `${getRandomUUid()}`,
			alignment: "right",
			hidden: false,
			layerName: "Resultados de elecciones 2020",
			title: "",
			image: "",
			description: `
			<p>
			Sin embargo, en las elecciones presidenciales de 2004, cuatro de cada diez hispanos votaron por el republicano George W. Bush. Y, desde 2012, los hispanos demócratas han disminuido, mientras que los republicanos han aumentado. 
			</p>`,

			location: {
				center: [-100.50106, 34.41058],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "condados_dems",
					opacity: 0,
				},

				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: `${getRandomUUid()}-hispano-por-partido`,
			alignment: "right",
			hidden: false,
			title: "",
			layerName: "Resultados de elecciones 2020",
			image: "",
			isChart: true,
			description: `<div id="hispano-por-partido-chart"></div>
			<p class="${style["latinMap__iframe__cite__chart"]}"> 
								<span class="${style["latinMap__iframe__cite__chart--bold"]}">Fuente:</span>
								<cite class="${style["latinMap__iframe__cite__chart--italic"]}">American Society/Council of the Americans</cite>.
			</p>
			`,
			location: {
				center: [-100.50106, 34.41058],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},

		{
			id: getRandomUUid(),
			alignment: "right",
			hidden: false,
			title: `¿Cuál es la causa del declive azul entre los hispanos? Existen varios factores. <span class="${style["latinMap__iframe__cite--bold"]}">Primero</span>, está el componente generacional: a mayor edad, el voto demócrata tiende a disminuir (AS/COA). <span class="${style["latinMap__iframe__cite--bold"]}">Segundo</span>, el país de origen influye en las prioridades políticas de los votantes hispanos. <span class="${style["latinMap__iframe__cite--bold"]}">Tercero</span>, muchos hispanos son católicos, lo que los lleva a tener valores más conservadores.`,
			layerName: "Resultados de elecciones 2020",
			isImage: true,
			image:
				"https://storage.googleapis.com/nmas-mapbox-images/story_telling/voto_latino/hombre_venezolano.jpg",
			description: `<p class="${style["latinMap__iframe__cite"]}"> 
			Un hombre venezolano señala su gorra de Trump mientras celebra el cambio de liderazgo presidencial de Venezuela en Orlando, Florida, 2024.
								<span class="${style["latinMap__iframe__cite--bold"]}">Fuente:</span>
								<cite class="${style["latinMap__iframe__cite--italic"]}">Miguel J. RodrigueImagesillo (Getty Images)</cite>.
						</p>`,
			location: {
				center: [-100.50106, 34.41058],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: `${getRandomUUid()}`,
			alignment: "left",
			hidden: false,
			layerName: "Resultados de elecciones 2020",
			title: "",
			image: "",
			description: `El discurso también juega un papel clave. Mike Madrid, consultor republicano, señala que el Partido Demócrata a menudo asume que los latinos deben ser tratados como trabajadores agrícolas o indocumentados, aunque la mayoría ya no encaja en ese perfil (<i>Axios</i>).`,
			location: {
				center: [-100.50106, 34.41058],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "condados_dems",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020",
					opacity: 1,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "right",
			hidden: false,
			title:
				"Sisto Abeyta, consultor demócrata, sugiere que el partido ha centrado su mensaje en votantes blancos y latinos con educación universitaria (Axios). Esto deja fuera a los hispanos de zonas rurales o fronterizas, para quienes el aborto y el medio ambiente no son temas prioritarios.",
			layerName: "Resultados de elecciones 2020",
			isImage: true,
			image:
				"https://storage.googleapis.com/nmas-mapbox-images/story_telling/voto_latino/mujeres_catolicas.jpg",
			description: `<p class="${style["latinMap__iframe__cite"]}"> 
			Mujeres católicas peruanas marchan en procesión en honor a San Martín de Porres en East Village, Nueva York, 2007. 
								<span class="${style["latinMap__iframe__cite--bold"]}">Fuente:</span>
								<cite class="${style["latinMap__iframe__cite--italic"]}">Mario Tama (Getty Images).</cite>
						</p>`,
			location: {
				center: [-100.50106, 34.41058],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "right",
			hidden: false,
			title: "",
			layerName: "Resultados de elecciones 2020",
			isImage: false,
			image: "",
			description: `Además, Peter Laffin de NCR, un influyente medio católico, destaca que los hispanos suelen tener posturas más conservadoras en economía, educación, cultura e inmigración.`,
			location: {
				center: [-100.50106, 34.41058],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},

		{
			id: `${getRandomUUid()}`,
			alignment: "right",
			hidden: false,
			layerName: "Resultados de elecciones 2020",
			title: "",
			image: "",
			description: `
			<span class="${style["latinMap__iframe__cite__chart--bold"]}">¿Cómo se explica este avance republicano en el electorado hispano?</span> Un fenómeno llama la atención al ver cómo votaron los condados con más hispanos en la elección de 2020. `,
			location: {
				center: [-100.50106, 34.41058],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "condados_dems",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020",
					opacity: 1,
				},
			],
			onChapterExit: [],
		},
		{
			id: `${getRandomUUid()}`,
			alignment: "right",
			hidden: false,
			layerName: "Resultados de elecciones 2020",
			title: "",
			image: "",
			description: `En los sitios de Texas donde ha crecido más el porcentaje de hispanos en los últimos años, han aumentado los votos para el Partido Republicano. En contraste, los condados ganados por los demócratas son los más hispanos del estado y donde esta composición demográfica se ha mantenido constante.`,
			location: {
				center: [-100.50106, 34.41058],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "condados_dems",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "resultados_2020",
					opacity: 1,
				},
			],
			onChapterExit: [],
		},

		{
			id: `${getRandomUUid()}-texas-eleccion-presidencial`,
			alignment: "left",
			hidden: false,
			layerName: "Resultados de elecciones 2020",
			title: "",
			image: "",
			description: `<div id="texas-eleccion-presidencial-chart"></div>
			<p class="${style["latinMap__iframe__cite__chart"]}"> 
								<span class="${style["latinMap__iframe__cite__chart--bold"]}">Fuente:</span>
								<cite class="${style["latinMap__iframe__cite__chart--italic"]}">Censos de población de EUA</cite>.
			</p>
			`,
			isChart: true,
			location: {
				center: [-100.50106, 34.41058],
				zoom: 3.68,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "liberty_county_text",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos_2",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "left",
			layerName: "Liberty County",
			hidden: false,
			title: "",
			image: "",
			description: `Por ejemplo, Liberty County, Texas, es un condado con 91 mil habitantes. Ahí, la población hispana se triplicó en los últimos veinte años. Pasaron de ser 1 de cada 10 personas del condado a alrededor de 3 de cada 10. Desde los años 90, los republicanos han ampliado su ventaja sobre los demócratas. En la elección de 2020, Donald Trump ganó este lugar con 60 puntos de diferencia sobre Joe Biden.`,
			location: {
				center: [-94.7429, 30.2623],
				zoom: 7.7,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county_text",
					opacity: 1,
				},
				{
					layer: "liberty_county",
					opacity: 1,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "left",
			hidden: false,
			layerName: "Starr County",
			title: "",
			image: "",
			description: `En la frontera de Texas y Tamaulipas, yace Starr County. Desde los años 80, prácticamente toda la población de este condado ya era hispana. El último censo del 2020 muestra que el 97.7% de los habitantes pertenece a esta mayoría.`,
			location: {
				center: [-98.86291, 25.58022],
				zoom: 7.12,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "liberty_county_text",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 1,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "swing_states",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "left",
			hidden: false,
			layerName: "Starr County",
			title: "",
			image: "",
			description: `Starr County (Rio Grande City) es uno de los condados más demócratas de Texas: desde la elección del año 2000, el partido azul ha ganado el estado por márgenes de hasta 60 puntos; sin embargo, en 2020, el presidente Joe Biden ganó por tan solo 5 puntos de diferencia. Esto muestra signos de la reducción de la brecha con los republicanos.`,
			location: {
				center: [-98.86291, 25.58022],
				zoom: 7.12,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 1,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "liberty_county_text",
					opacity: 0,
				},
				{
					layer: "swing_states",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "right",
			hidden: false,
			layerName: "Estados columpio",
			title: "",
			image: "",
			description: `En noviembre de 2024 hay elecciones presidenciales en EUA. Al promediarse, las principales encuestas señalan un empate técnico entre la candidata demócrata, Kamala Harris, y el candidato republicano, Donald Trump.

			En el sistema político estadounidense, cada estado tiene un peso distinto de votos en el Colegio Electoral. Para ganar la presidencia, un candidato debe alcanzar 270 votos electorales. `,
			location: {
				center: [-97.81269, 31.87194],
				zoom: 2.62,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "swing_states",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "left",
			hidden: false,
			layerName: "Estados columpio",
			title: "",
			image: "",
			description: `Por razones históricas e institucionales del país, la mayoría de los estados tienen una tendencia partidista clara: o votan en su mayoría por los demócratas (en azul) o se inclinan por los republicanos (en rojo).`,
			location: {
				center: [-97.81269, 31.87194],
				zoom: 2.62,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "swing_states",
					opacity: 1,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "left",
			hidden: false,
			layerName: "Estados columpio",
			title: "",
			image: "",
			description: `Pero hay un puñado de estados en los que el resultado se decide por un margen de victoria delgado. Se les llama “estados columpio”, “estados bisagra” o <i>swing states</i>, en inglés. En estos siete lugares (en gris), se espera que se defina el resultado de la elección: Wisconsin, Michigan, Pensilvania, Carolina del Norte, Georgia, Arizona y Nevada.`,
			location: {
				center: [-97.81269, 31.87194],
				zoom: 2.62,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "swing_states",
					opacity: 1,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "left",
			hidden: false,
			layerName: "% de votantes hispanos por estado",
			title: "",
			image: "",
			description: `Dos de los llamados <i>swing states</i> que podrían definir la elección de 2024, tienen grandes poblaciones de hispanos: Arizona (25%) y Nevada (22%). En estos estados, el ganador de la última elección se definió por menos de 3 puntos porcentuales.`,
			location: {
				center: [-114.42685, 34.53295],
				zoom: 4.19,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 0,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "swing_states",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 1,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "right",
			layerName: "Resultados de elecciones 2020",
			hidden: false,
			title: "",
			image: "",
			description: `En el país, los dos temas que más preocupaban a los votantes hispanos en las elecciones intermedias de 2022 son los mismos que conciernen al grueso de la población en 2024: economía (más para los republicanos) y seguridad social (especialmente para los demócratas). Pero el tercer tema más importante en su agenda sí es distinto: la educación.`,
			location: {
				center: [-98.48696, 34.09536],
				zoom: 3.5,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
				{
					layer: "swing_states",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "right",
			layerName: "Resultados de elecciones 2020",
			hidden: false,
			title: "",
			image: "",
			description: `Estos temas varía de elección a elección, en función del momento político o social del país. Adicionalmente, los asuntos que preocupaban a los hispanos de primera generación no son los mismos que los que hoy preocupan a los de segunda o tercera generación, quienes, a diferencia de sus padres, nacieron en los Estados Unidos.`,
			location: {
				center: [-98.48696, 34.09536],
				zoom: 3.5,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
				{
					layer: "swing_states",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: `${getRandomUUid()}-problematicas-hispanos`,
			alignment: "right",
			hidden: false,
			layerName: "Resultados de elecciones 2020",
			title: "",
			image: "",
			isChart: true,
			description: `<div id="problematicas-hispanos-chart"></div>
			<p class="${style["latinMap__iframe__cite__chart"]}"> 
								<span class="${style["latinMap__iframe__cite__chart--bold"]}">Fuente:</span>
								<cite class="${style["latinMap__iframe__cite__chart--italic"]}">Pew Research Center, 2024.</cite>
			</p>`,
			location: {
				center: [-98.48696, 34.09536],
				zoom: 3.5,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: `${getRandomUUid()}-problematica-usa`,
			alignment: "right",
			hidden: false,
			title: "",
			layerName: "Resultados de elecciones 2020",
			image: "",
			isChart: true,
			description: `<div id="problematica-usa-chart"></div>
			<p class="${style["latinMap__iframe__cite__chart"]}"> 
								<span class="${style["latinMap__iframe__cite__chart--bold"]}">Fuente:</span>
								<cite class="${style["latinMap__iframe__cite__chart--italic"]}">Pew Research Center</cite>.
			</p>
			`,
			location: {
				center: [-98.48696, 34.09536],
				zoom: 3.5,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "left",
			hidden: false,
			title: "",
			layerName: "Resultados de elecciones 2020",
			image: "",
			description: `
			La lista de temas que preocupan a los hispanos a nivel nacional varía de estado a estado. Sería un error suponer que, por ejemplo, los cubanos de Florida, votan de la misma manera que los mexicanos de California y Texas. A los primeros les interesa más la economía y el costo de vida lo mismo que a los segundos; mientras que a los texanos, la seguridad fronteriza y la inmigración.
			<p class="${style["latinMap__iframe__cite__text"]}">
            <span class="${style["latinMap__iframe__cite__chart--bold"]}">Fuente:</span><cite class="${style["latinMap__iframe__cite__chart--italic"]}"> TelevisaUnivision Consumer Strategy & Insights; PPIC Statewide Surveys; y Universidad de Texas en Austin.</cite>
			</p>
			`,
			location: {
				center: [-98.48696, 34.09536],
				zoom: 3.5,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "left",
			hidden: false,
			title: "",
			layerName: "Resultados de elecciones 2020",
			image: "",
			description: `Cada estado tiene sus propias dinámicas y, en cada uno, hay temas que preocupan más que otros a la población hispana. Los hispanos están cambiando la política de Estados Unidos, pero también la política y el contexto local están influyendo en ellos, en sus preferencias  y decisiones electorales.`,
			location: {
				center: [-98.48696, 34.09536],
				zoom: 3.5,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "right",
			layerName: "Resultados de elecciones 2020",
			hidden: false,
			title: "",
			image: "",
			description: `Sin embargo, a pesar de la importancia numérica de los hispanos, hay un fenómeno que les impide tener mayor peso en las elecciones: son el grupo que menos sale a votar, en comparación con los blancos, los afroamericanos y otros.`,

			location: {
				center: [-98.48696, 34.09536],
				zoom: 3.5,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: `${getRandomUUid()}-participacion-por-raza`,
			alignment: "right",
			hidden: false,
			layerName: "Resultados de elecciones 2020",
			title: "",
			image: "",
			description: `<div id="participacion-por-raza-chart"></div>
			<p class="${style["latinMap__iframe__cite__chart"]}"> 
								<span class="${style["latinMap__iframe__cite__chart--bold"]}">Fuente:</span>
								<cite class="${style["latinMap__iframe__cite__chart--italic"]}">Election Lab, University of Florida</cite>.
			</p>
			`,
			isChart: true,
			location: {
				center: [-98.48696, 34.09536],
				zoom: 3.5,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "right",
			hidden: false,
			layerName: "Resultados de elecciones 2020",
			title: "",
			image: "",
			description: `Los hispanos, lejos de ser una comunidad homogénea, son un grupo cuyos intereses varían según su estado, edad o clase social. Las nuevas generaciones se están adentrando a los debates nacionales del momento, tales como la economía, el costo de vida y la inmigración (<i>GenForward</i>, Universidad de Chicago); y al mismo tiempo, su identidad hispana se atenúa a medida que pasa el tiempo (<i>Pew Research Center</i>).`,

			location: {
				center: [-98.48696, 34.09536],
				zoom: 3.5,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "left",
			hidden: false,
			title: "",
			layerName: "Resultados de elecciones 2020",
			image: "",
			description: `En esta elección y las siguientes, cada partido buscará —a su manera— el voto de esta comunidad, vibrante y en crecimiento. Son más de 36 millones hispanos llamados a ir a las urnas y su participación podría influir de manera histórica en los resultados.`,

			location: {
				center: [-98.48696, 34.09536],
				zoom: 3.5,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "center",
			hidden: false,
			title: "",
			layerName: "Resultados de elecciones 2020",
			image: "",
			description: `
			<p>
			<p class="${style["latinMap__iframe__cite--bold"]}">Créditos</p>

			<p>
			<span class="${style["latinMap__iframe__cite--bold"]}">Investigación:</span>
			<span>Juan Pablo F. Mansur, Eduardo L. Cafaggi y Eduardo Gonzaga</span>
			</p>

			<p>
			<span class="${style["latinMap__iframe__cite--bold"]}">Gráficos y Mapas:</span>
			<span>Ximena Medina, Juan Güemez, Yamileth Güido y Eduardo Gonzaga</span>
			</p>

			<p>
			<span class="${style["latinMap__iframe__cite--bold"]}">Desarrollo web:</span>
			<span>Fernando Sosa</span>
			</p>
			
			<p>
			<span class="${style["latinMap__iframe__cite--bold"]}">Dirección:</span>
			<span>Fernanda Caso</span>
			</p>
			</p>
			`,

			location: {
				center: [-98.48696, 34.09536],
				zoom: 3.5,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
		{
			id: getRandomUUid(),
			alignment: "center",
			hidden: false,
			title: "",
			layerName: "Resultados de elecciones 2020",
			image: "",
			description: `Derechos reservados © Tritón Comunicaciones S.A de C.V.`,

			location: {
				center: [-98.48696, 34.09536],
				zoom: 3.5,
				pitch: 0,
				bearing: 0,
			},
			mapAnimation: "flyTo",
			rotateAnimation: false,
			callback: "",
			onChapterEnter: [
				{
					layer: "resultados_2020",
					opacity: 1,
				},
				{
					layer: "starr_county",
					opacity: 0,
				},
				{
					layer: "liberty_county",
					opacity: 0,
				},
				{
					layer: "resultados_2020_2",
					opacity: 0,
				},
				{
					layer: "votantes_hispanos",
					opacity: 0,
				},
			],
			onChapterExit: [],
		},
	],
};
