export const socialInfo = [
	{
		title: "Facebook",
		link: "https://www.facebook.com/nmas.com.mx",
		icon: "Facebook",
	},
	{
		title: "Twitter",
		link: "https://twitter.com/nmas",
		icon: "x_twitter",
	},
	{
		title: "Instagram",
		link: "https://www.instagram.com/n.mas/",
		icon: "Instagram",
	},
	{
		title: "YouTube",
		link: "https://www.youtube.com/channel/UCUsm-fannqOY02PNN67C0KA",
		icon: "YouTube",
	},
	{
		title: "Tik Tok",
		link: "https://www.tiktok.com/@n.mas?lang=es",
		icon: "TikTokWhite",
	},
	{
		title: "Threads",
		link: "https://www.threads.net/@n.mas",
		icon: "threads",
	},
];
