function diffInHours(date1: Date, date2: Date): number {
	const diffInMs = date1.getTime() - date2.getTime();
	return Math.floor(diffInMs / (1000 * 60 * 60));
}

function diffInMinutes(date1: Date, date2: Date): number {
	const diffInMs = date1.getTime() - date2.getTime();
	return Math.floor(diffInMs / (1000 * 60));
}
/**
 * 
 * @param dateModified The date to set the diff from now, must be a Date object
 * @returns An array, the first element is about the text when the time is less than a minute and also when we have hours as diff time. The second element is the minutes diff and the third element the hours diff [string, number, number]
 */
export function setTimeDiffText(dateModified: Date): [string, number, number] {
	const now = new Date();
	const diffMinutesModified = diffInMinutes(now, dateModified);

	if (diffMinutesModified < 1) {
		return [`Hace un momento`, 0, 0];
	} else if (diffMinutesModified < 60) {
		return [
			`Hace ${diffMinutesModified} ${
				diffMinutesModified === 1 ? "minuto" : "minutos"
			}`,
			diffMinutesModified,
			0,
		];
	} else {
		const diffHoursModified = diffInHours(now, dateModified);
		return [
			`Hace ${diffHoursModified} ${diffHoursModified === 1 ? "hora" : "horas"}`,
			0,
			diffHoursModified,
		];
	}
}
