export const programas = {
	data: {
		getProgrammingGrid: {
			type: "block_content--bloque_parrilla_programacion",
			id: "f5bec5a9-ad79-458f-b5d5-1bdf30f47e8e",
			channels: [
				{
					canal_name: "Foro TV",
					logo_canal: {
						imageAlt: "Foro TV",
						imageUrl: null,
					},
					status: true,
					ui_live: "televisa",
					path_live: "forotv-en-vivo",
					uid: "0000018d-eb78-da32-abdd-fffce6160000",
					codigo_canal: "16462",
				},
				{
					canal_name: "Noticieros",
					logo_canal: {
						imageAlt: "Noticieros",
						imageUrl: null,
					},
					status: true,
					ui_live: "televisa",
					path_live: "noticieros-en-vivo",
					uid: "0000018d-eb81-da45-a7cd-ef8909f40000",
					codigo_canal: "16461",
				},
			],
		},
	},
};
