import Image from "next/image";

const Video = (idPlayer: string, placeholderSrc?: string) => {
	return (
		<div
			id="player-wrapper"
			className="hmedia video-stream u-video"
			style={{
				width: "100%",
				height: "100%",
				position: "absolute",
				backgroundColor: "#00283B",
			}}
		>
			<div className="lds-dual-ring" />
			<Image
				src={placeholderSrc ?? "/assets/placeholder_.svg"}
				alt="Video imagen default "
				width="384"
				height="216"
				style={{
					position: "absolute",
					top: "0",
					left: "0",
					width: "100%",
					height: "100%",
					objectFit: "contain",
				}}
				loading="lazy"
				placeholder="blur"
				blurDataURL={`${
					process.env.NEXT_PUBLIC_BLURIMAGE ||
					"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
				}`}
			/>

			<div id={idPlayer} />
		</div>
	);
};
export default Video;
