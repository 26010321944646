import { dataLive } from "@/app/types/BaseImageType";

export const getAttributesVideoStreaming = (
	idPlayer: string,
	muted: boolean,
	autoplay: boolean,
	liveUrl: dataLive
) => {
	return {
		nodeId: idPlayer,
		ui: liveUrl?.ui_live,
		path: liveUrl?.path_live,
		autoplay,
		muted,
		captions: false,
		poster: "preview240",
		autopause: {
			unique: true,
		},
		ga: {
			id: process.env.NEXT_PUBLIC_UA_DEFAULT || "UA-54200951-49",
		},
	};
};

export const getAttributesVideoVod = (
	idPLayer: string,
	muted: boolean,
	idVideo: any,
	type: string,
	autoplay: boolean,
	controls: boolean,
	loop: boolean,
	path: string,
	options?: any
) => {
	const network = "televisa.nmas/nmas-media/videos/vod";
	const dfp = {
		networkName: !window?.location?.href?.includes("/preview/")
			? `${network}/${type}`
			: "",
		cmsid: 2603811,
	};

	const playerConf = {
		nodeId: idPLayer,
		mcpId: idVideo,
		autoplay,
		muted,
		captions: false,
		loop,
		poster: "preview340",
		autopause: {
			unique: true,
		},
		ga: {
			id: process.env.NEXT_PUBLIC_UA_DEFAULT || "UA-54200951-49",
		},
		parent: {
			site: "nmas",
		},
		...options,
		disablePosterImage: true,
	};

	if (path === "/descubre") {
		return playerConf;
	} else {
		return { ...playerConf, ...{ dfp } };
	}
};
export const getAttributesPlaylistVod = (
	idPLayer: string,
	muted: boolean,
	idVideo: any,
	type: string,
	autoplay: boolean,
	playlistVideos: any[]
) => {
	const network = "televisa.nmas/nmas-media/videos/vod";
	return {
		nodeId: idPLayer,
		mcpId: idVideo,
		mcpIds: playlistVideos,
		autoplay,
		muted,
		poster: "preview240",
		autopause: {
			unique: true,
		},
		ga: {
			id: process.env.NEXT_PUBLIC_UA_DEFAULT,
		},
		dfp: {
			networkName: !window?.location?.href?.includes("/preview/")
				? `${network}/${type}`
				: "",
			cmsid: 2603811,
		},
	};
};
